import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import {toggleLoader} from "../../shared/actions/setting";
import {useDispatch, useSelector} from "react-redux";
import {MODULE} from "../../utils/Enum";
import Container from "react-bootstrap/Container";
import Switch from "react-switch";
import {toast} from "react-toastify";
import {getSelectedFarmModule, getSelectedUserModule,} from "../../actions/module";
import {formatDisplayEnumValue} from "../../utils/Utils";
import FMS from "../../images/FMS.svg"
import BirdView from "../../images/birdview.svg"
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../shared/utils/utils";
import {getUserId, isSuperLevelUsers} from "../../shared/utils/SharedAuthentication";

const ModuleConfig = (props) => {
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [dailyUpdate, setDailyUpdate] = useState(false);
  const [cashFlow, setCashFlow] = useState(false);
  const [smartFarm, setSmartFarm] = useState(false);
  const [weatherUpdate, setWeatherUpdate] = useState(false);
  const [birdview, setBirdView] = useState(false);
  const [fms, setFMS] = useState(false);
  const {farmId} = useParams();

  const farmModules = useSelector((state) => {
    return state.modules.farmModules;
  });
  const userModules = useSelector((state) => {
    return state.modules.enabledModules;
  });

  const farm = useSelector((state) => {
    return state.farmList.selectedFarm;
  });

  const [weatherSwitch, setWeatherSwitch] = useState(farm.weatherEnabled);

  useEffect(() => {
    if (isEmpty(farmModules)) {
      return;
    }
    setCashFlow(farmModules[farmId][MODULE.CASH_FLOW]);
    setDailyUpdate(farmModules[farmId][MODULE.DAILY_UPDATE]);
    setSmartFarm(farmModules[farmId][MODULE.SMART_FARM]);
    setBirdView(farmModules[farmId][MODULE.BIRD_VIEW]);
    setFMS(farmModules[farmId][MODULE.FMS]);
  }, [farmModules]);

  useEffect(() => {
    setWeatherSwitch(farm.weatherEnabled);
  }, [farm]);

  function onchange(v, e) {
    let update = {}
    update.cashFlow = cashFlow
    update.smartFarm = smartFarm
    update.dailyUpdate = dailyUpdate
    update.birdView = birdview
    update.FMS = fms

    if (v === MODULE.CASH_FLOW) {
      setCashFlow(e);
    } else if (v === MODULE.SMART_FARM) {
      setSmartFarm(e);
    } else if (v === MODULE.DAILY_UPDATE) {
      setDailyUpdate(e);
    } else if (v === MODULE.BIRD_VIEW) {
      setBirdView(e);
    } else if (v === MODULE.FMS) {
      setFMS(e);
    }
    dispatch(toggleLoader(true))
    axios
      .put(
        process.env.REACT_APP_HOST +
        "/user/" +
        getUserId() +
        "/farm/" +
        farmId +
        "/moduleConfig" +
        "?module=" +
        v +
        "&value=" +
        e
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            getSelectedUserModule({...res.data.content.enabledModules})
          );
          dispatch(getSelectedFarmModule({...res.data.content.farmModules}));
          if (e) {
            toast.success(
              formatDisplayEnumValue(v) +
              " Module is added to " +
              farm.name +
              " farm"
            );
          } else {
            toast.success(
              formatDisplayEnumValue(v) +
              " Module is removed from " +
              farm.name +
              " farm"
            );
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
          if (v === MODULE.CASH_FLOW) {
            setCashFlow(update.cashFlow);
          } else if (v === MODULE.SMART_FARM) {
            setSmartFarm(update.smartFarm);
          } else if (v === MODULE.DAILY_UPDATE) {
            setDailyUpdate(update.dailyUpdate);
          } else if (v === MODULE.BIRD_VIEW) {
            setBirdView(update.birdView);
          } else if (v === MODULE.FMS) {
            setFMS(update.FMS);
          }

        } else {
          toast.error("Error");
          if (v === MODULE.CASH_FLOW) {
            setCashFlow(update.cashFlow);
          } else if (v === MODULE.SMART_FARM) {
            setSmartFarm(update.smartFarm);
          } else if (v === MODULE.DAILY_UPDATE) {
            setDailyUpdate(update.dailyUpdate);
          } else if (v === MODULE.BIRD_VIEW) {
            setBirdView(update.birdView);
          } else if (v === MODULE.FMS) {
            setFMS(update.FMS);
          }
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  useEffect(() => {
    if (!weatherUpdate) {
      return;
    }

    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST +
        "/user/" +
        getUserId() +
        "/farm/" +
        farmId +
        "/weather/" +
        weatherSwitch
      )
      .then((res) => {
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          console.log("Error " + error.response.data.message);
        }
      })
      .finally(() => {
        setWeatherUpdate(false);
        dispatch(toggleLoader(false));
      });
  }, [weatherUpdate]);

  return (
    <Container>
      <div className="single-card m-rl-m-8">
        {/* <div className="single-card-module"> */}
        <div className="feature border-bottom p-b-16">{t("title.FEATURES")}</div>
        <div className="feature-module">
          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.SMART_FARM]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="module-config-icon2">
                <div className="smart-farm-icon">
                  <FeatherIcon className={"h-20"} icon="book-open"/>
                </div>
              </div>
            </div>
            <div className={"col-md-10 col-10"}>
              <p className="config-setting">{t("title.SMART_FARM")}</p>
              <p className="config-setting-content">
                {t("title.SMART_FARM_PARAGRAPH")}
              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                <Switch
                  checked={smartFarm}
                  onColor={"#02C170"}
                  width={48}
                  height={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(event) => onchange(MODULE.SMART_FARM, event)}
                />
              </div>
            </div>
          </div>

          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.DAILY_UPDATE]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="module-config-icon3">
                <div className="daily-update-icon">
                  <FeatherIcon className={"h-20"} icon="check-square"/>
                </div>
              </div>
            </div>
            <div className={"col-md-10 col-10"}>
              <p className="config-setting">{t("title.DAILY_UPDATE")}</p>
              <p className="config-setting-content">
                {t("title.DAILY_UPDATE_PARAGRAPH")}
              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                <Switch
                  width={48}
                  height={25}
                  onColor={"#02C170"}
                  checked={dailyUpdate}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(event) => onchange(MODULE.DAILY_UPDATE, event)}
                />
              </div>
            </div>
          </div>
          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.CASH_FLOW]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="module-config-icon1">
                <div className="cash-flow-icon">
                  <FeatherIcon className={"h-20"} icon="dollar-sign"/>
                </div>
              </div>
            </div>
            <div className={"col-md-10 col-10 min-height-85"}>
              <p className="config-setting">{t("title.CASH_FLOW")}</p>
              <p className="config-setting-content">
                {t("title.CASH_FLOW_PARAGRAPH")}

              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                {/*<Form.Check   type="switch" id="cashFlow" name="inverseFalse"*/}
                {/*            label=""/>*/}
                <Switch
                  width={48}
                  height={25}
                  onColor={"#02C170"}
                  checked={cashFlow}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(event) => onchange(MODULE.CASH_FLOW, event)}
                />
              </div>
            </div>
          </div>

          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.BIRD_VIEW]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="module-config-icon2">
                <div className="cash-flow-icon">
                  <img className={"bird-view-icon"} src={BirdView} alt="BirdView"/>
                </div>
              </div>
            </div>
            <div className={"col-md-10 col-10"}>
              <p className="config-setting">{t("title.BIRD_VIEW")}</p>
              <p className="config-setting-content">
                {t("title.BIRD_VIEW_PARAGRAPH")}

              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                <Switch
                  width={48}
                  height={25}
                  onColor={"#02C170"}
                  checked={birdview}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(event) => onchange(MODULE.BIRD_VIEW, event)}
                />
              </div>
            </div>
          </div>

          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.FMS]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="module-config-icon4">
                <img className={"fms-icon"} src={FMS} alt="FMS"/>
              </div>
            </div>
            <div className={"col-md-10 col-10"}>
              <p className="config-setting">{t("title.FMS")}</p>
              <p className="config-setting-content">
                {t("title.FMS_PARAGRAPH")}

              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                <Switch
                  width={48}
                  height={25}
                  onColor={"#02C170"}
                  checked={fms}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(event) => onchange(MODULE.FMS, event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="feature border-bottom p-b-16">{t("title.ADD_ONS")}</div>
        <div className="feature-module">
          <div
            hidden={!isSuperLevelUsers() && !userModules[MODULE.SMART_FARM]}
            className={"row"}
          >
            <div className={"col-md-1 col-1"}>
              <div className="smart-farm-weather-icon">
                <div className="module-config-icon4">
                  {/* <img className={"icon-in-weather-module"} src={cloud} /> */}
                  <FeatherIcon className={"h-20"} icon="cloud-lightning"/>
                </div>
              </div>
            </div>
            <div className={"col-md-10 col-10"}>
              <p className="config-setting">{t("title.WEATHER")}</p>
              <p className="config-setting-content">
                {t("title.WEATHER_PARAGRAPH")}

              </p>
            </div>
            <div className={"col-md-1 col-1"}>
              <div className="toggle custom-switch-p">
                <Switch
                  checked={weatherSwitch}
                  onColor={"#02C170"}
                  width={48}
                  height={25}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={(checked) => {
                    setWeatherSwitch(checked);
                    setWeatherUpdate(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </Container>
  );
};

export default ModuleConfig;
