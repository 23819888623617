import React, {useEffect, useState} from "react";
import FeatherIcon from "feather-icons-react";
import Droplet from "../../../images/droplet.svg";
import DropletGreen from "../../../images/droplet-green.svg";
import Wind from "../../../images/wind.svg";
import WindGreen from "../../../images/wind-green.svg";
import CloudRain from "../../../images/cloud-rain-green.svg";
import Thermometer from "../../../images/thermometer.svg";
import Rain from "../../../images/rain.svg";
import C3Chart from "react-c3js";
import * as d3 from "d3";
import 'c3/c3.css';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import {first, map, pluck, where} from "underscore";
import {ExportToCsv} from "export-to-csv";
import {toggleLoader} from "../../../shared/actions/setting";
import {DateRangePicker} from "rsuite";
import {dateFormat} from "../../../shared/utils/utils";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";

export default function WeatherPop(props) {
  const [dataSet, setDataSet] = useState({});
  const [dataSetDaily, setDataSetDaily] = useState({});
  const [weatherTab, setWeatherTab] = useState("Temp");
  const [weatherTime, setWeatherTime] = useState("Hourly");
  const [isWeather, setIsWeather] = useState(false);
  const [weatherIcon, setWeatherIcon] = useState();
  const [weatherActual, setWeatherActual] = useState([]);
  const [weatherInfo, setWeatherInfo] = useState([]);
  const [dailyWeatherHistory, setDailyWeatherHistory] = useState([]);
  const [weatherDaily, setWeatherDaily] = useState([]);
  const [weatherMain, setWeatherMain] = useState();
  const [weatherTemp, setWeatherTemp] = useState();
  const [loadGraph, setLoadGraph] = useState(false);
  const [loadGraphDaily, setLoadGraphDaily] = useState(false);

  const [dateRange, setDataRange] = useState({
    fromDate: new Date(Date.now() - 604800000),
    toDate: new Date()
  });


  const [isVisibleExport, setIsVisibleExport] = useState(false)
  const {t} = useTranslation();


  const dispatch = useDispatch();


  const style1 = {borderBottom: "solid 3px #22C687"};

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });


  function styleGraph() {
    if (window.innerWidth < 769) {

      d3.select(".c3-axis-x-label").attr("dy", "42px");
      d3.selectAll(".tick").style("font-size", "7px");
      d3.select(".c3-axis-y-label").attr("dy", "-34px");
    } else {
      d3.select(".c3-axis-y-label").attr("dy", "-36px");
    }
    d3.selectAll(".c3-legend-item").attr("x", "400");

  }

  async function addDataGraphDate(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSet(graphData)
    });
  }

  async function addDataGraphDateDaily(graphData) {
    await new Promise((resolve, reject) => {
      resolve(1);
      setDataSetDaily(graphData)
    });
  }

  useEffect(() => {
    if (dataSet.length) {
      dataSet.slice(0, dataSet.length);
    }
    if (!weatherInfo || weatherInfo.length === 0) {
      return;
    }
    setLoadGraph(false);
    setLoadGraphDaily(false);
    if (weatherTime === 'Hourly') {
      drawGraphHourly()
      // drawGraph()
    } else {
      // drawGraph()

      drawGraphDaily()
    }
  }, [weatherInfo, weatherTab, weatherTime]);


  useEffect(() => {
    if (!selectedFarm.location) {
      return
    }
    axios.get(process.env.REACT_APP_HOST + `/weather/user/` + getUserId() + `/weather/city/name/` + selectedFarm.location)
      .then(res => {
        setWeatherInfo(res.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => {
    })
    ///user/{userId}/weather/daily/city/name/{cityName}
    axios.get(process.env.REACT_APP_HOST + `/weather/user/` + getUserId() + `/weather/daily/city/name/` + selectedFarm.location)
      .then(res => {
        setWeatherDaily(res.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => {
    })

  }, [selectedFarm])

  useEffect(() => {
    if (!weatherInfo || weatherInfo.length <= 0) {
      return
    }
    if (first(weatherInfo).readingType === "FORECAST") {

      setWeatherActual([first(weatherInfo)])
    } else {
      setWeatherActual(where(weatherInfo, {readingType: "ACTUAL"}))
    }
    setWeatherIcon(pluck(pluck(pluck(weatherInfo, 'current'), 'weather')[0], 'icon')[0])
    setWeatherMain(pluck(pluck(pluck(weatherInfo, 'current'), 'weather')[0], 'main')[0])
    setWeatherTemp(pluck(weatherInfo, 'current')[0].temp)

  }, [weatherInfo])


  function whichGraph() {
    if (weatherTab === "Temp") {
      return 'temp'
    } else if (weatherTab === "Rain") {
      return 'rain'
    } else if (weatherTab === "Humidity") {
      return 'humidity'
    } else if (weatherTab === "Wind") {
      return 'wind_speed'
    }
  }

  var ary = ['Temp', 'Rain', 'Humidity', 'Wind'];

  function unload(type) {
    Array.prototype.remove = function () {
      var what, a = arguments, L = a.length, ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };
    ary.remove(type);
    return ary
  }


  function drawGraphHourly(tab) {
    const durationCurrentAggregated = weatherTab !== 'Rain' ? pluck(pluck(weatherInfo, 'current'), whichGraph(weatherTab)) : map(pluck(pluck(pluck(weatherInfo, 'current'), 'rain'), '1h'), (e) => e ? e : '0');
    const date = pluck(weatherInfo, 'time');
    const dateSliced = [];
    date.map(d => {
      dateSliced.push(d.slice(0, 16));
    });

    const graphData = {};
    graphData.data = null;
    graphData.axis = null;
    const tooltip = {
      format: {
        value: function (value, ratio, id, index) {
          return value;
        }
      },
    };

    const data = {
      // xs: {
      //     [weatherTab]: 'Time',
      // },
      x: 'Time',
      xFormat: '%Y-%m-%d %H:%M',
      columns: [
        [weatherTab].concat(durationCurrentAggregated),
        ['Time'].concat(dateSliced),
      ],
      colors: {
        [weatherTab]: '#4DB6AC'
      },
      unload: unload(weatherTab),
      type: 'spline',
    };
    let axis

    axis = {
      x: {
        show: true,
        type: 'timeseries',
        tick: {
          // multiline: false,
          format: '%Y-%m-%d %H:%M',
          count: 50,
          fit: true,
          rotate: window.innerWidth > 767 ? 0 : 9,
          culling: {
            max: window.innerWidth > 767 ? 6 : 5,
          },
          height: 100,


        },
        label: {
          text: 'Time',
          position: 'outer-center',
        },

      },
      y: {
        label: {
          text: weatherTab,
          position: 'outer-middle',

        }
      },
    };
    const zoom = {
      rescale: true

    };
    graphData['data'] = data;
    graphData['axis'] = axis;
    graphData['tooltip'] = tooltip;
    graphData['zoom'] = zoom;

    addDataGraphDate(graphData).then(() => {
      setLoadGraph(true);
      console.log("drawing graph");
    });

  }

  function drawGraphDaily() {
    const durationCurrentAggregated = weatherTab === "Temp" ? pluck(pluck(pluck(weatherDaily, 'dailyWeather'), 'temp'), 'day') : pluck(pluck(weatherDaily, 'dailyWeather'), whichGraph(weatherTab));
    const dateSliced = pluck(weatherDaily, 'date');

    const graphData = {};
    graphData.data = null;
    graphData.axis = null;
    const tooltip = {
      format: {
        value: function (value, ratio, id, index) {
          return value;
        }
      },
    };

    const data = {
      // xs: {
      //     [weatherTab]: 'Time',
      // },
      x: 'Time',
      xFormat: '%Y-%m-%d',
      columns: [
        [weatherTab].concat(durationCurrentAggregated),
        ['Time'].concat(dateSliced),
      ],
      unload: unload(weatherTab),
      type: 'spline',
    };
    let axis

    axis = {
      x: {
        show: true,
        type: 'timeseries',
        tick: {
          // multiline: false,
          format: '%Y-%m-%d',
          count: 14,
          fit: true,
          rotate: window.innerWidth > 767 ? 0 : 9,
          culling: {
            max: window.innerWidth > 767 ? 6 : 5,
          },
          height: 100,


        },
        label: {
          text: 'Time',
          position: 'outer-center',
        },

      },
      y: {
        label: {
          text: weatherTab,
          position: 'outer-middle',

        }
      },
    };
    const zoom = {
      rescale: true

    };
    graphData['data'] = data;
    graphData['axis'] = axis;
    graphData['tooltip'] = tooltip;
    graphData['zoom'] = zoom;

    addDataGraphDateDaily(graphData).then(() => {
      setLoadGraphDaily(true)
    });

  }

  function exportPastData(e) {
    dispatch(toggleLoader(true));

    // const todayDate = dateFormat(new Date());
    let fromDate = e[0] ? e[0] : dateRange.fromDate;
    let toDate = e[1] ? e[1] : dateRange.toDate;

    // let toDate = dateRange ?dateFormat( dateRange.toDate) : todayDate;

    // axios.get(process.env.REACT_APP_HOST + `/weather/user/` + getUserId() + `/weather/pastWeather/city/name/`+selectedFarm.location+"?from="+fromDate+"&to="+toDate)
    axios.get(process.env.REACT_APP_HOST + `/weather/user/` + getUserId() + `/weather/pastWeatherDaily/city/name/` + selectedFarm.location + "?from=" + dateFormat(fromDate) + "&to=" + dateFormat(toDate))
      .then(res => {
        dispatch(toggleLoader(false));

        setDailyWeatherHistory(res.data.content)
        // console.log(res.data.content);

        exportData(res.data.content)
      }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        console.log("Error " + error.response.data.message);
      }
    }).finally(() => {
    })
  }

  function exportData(dataAll) {
    let headers = ["Date", "Temperature (C)", "Humidity", "Rain (mm)", "Wind Speed"];


    const data = [];

    for (const e of dataAll) {
      if (!e || !e.dailyWeather) {
        continue;
      }

      data.push({
        "Date ": e.date,
        "Temperature ": e.dailyWeather.temp.day,
        "Humidity ": e.dailyWeather.humidity,
        "Rain ": e.dailyWeather.rain ? e.dailyWeather.rain : 0,
        "Wind Speed": e.dailyWeather.wind_speed
      });
    }


    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Summary',
      useBom: true,
      noDownload: false,
      headers: headers,
      filename: "WeatherHistory",
      nullToEmptyString: true,
    };
    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }

  function onCheck(e) {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);


  }

  return (
    <div>
      <div>
        {selectedFarm.weatherEnabled && weatherIcon &&
          <div className={"hide-768 weather-box"} onClick={() => setIsWeather(true)}>
            {weatherIcon &&
              <img src={`/images/clouds/${weatherIcon}.png`} width={'48px'} className={'mt-1'}
                   style={{mixBlendMode: 'multiply'}}/>}
            {weatherMain &&
              <div className={'weather-box-heading'} data-toggle="tooltip" title={weatherMain}>{weatherMain}</div>}
            {weatherTemp && <div className={'weather-box-temp'}>{weatherTemp + " °C"} </div>}
          </div>}
      </div>
      {isWeather && <div className="sa-popup-bg ">
        <div className="sa-popup hide-425">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header">
              <span className={'sa-model-heading '}>Weather</span>
              <div className="sa-popup-close-icon" onClick={() => setIsWeather(false)}><FeatherIcon
                className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {weatherActual.map((data, index) => (
              <div key={index}>
                <div className="sa-popup-content">


                  {/*<div className={'col-lg text-left'}>*/}
                  {/*    <div className={'row'}>*/}
                  {/*        <div className={'col-4 text-right'}>*/}
                  {/*            <div className={'weather-icon-box'}>*/}
                  {/*                <img src={CloudRain} width={"24px"}/></div>*/}
                  {/*        </div>*/}
                  {/*        <div className={'col-8'}>*/}
                  {/*            <div className={'weather-box-temp'}>0 %</div>*/}
                  {/*            <div className={'weather-name'}>Precipitation</div>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}

                  {/*</div>*/}


                  <div style={{width: 280, marginLeft: 680, justifyContent: 'right'}}>


                    <Dropdown className="sa-table-float-left participant-filter">
                      <Dropdown.Toggle className={"sa-filter sa-table-h-float-l"} id="dropdown-basic">


                        <span className={"sa-table-icon"}>Export</span>

                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{height: 30}} className={"single-card-content-export-csv"}>
                        <DateRangePicker
                          style={{marginTop: 10, marginRight: 10, marginLeft: 10}}

                          // disabledDate={date => date > new Date()} disabled={false}
                          onChange={(e) => onCheck(e)}
                          oneTap={false} onOk={(e) => exportPastData(e)
                        }
                          // defaultValue={[dateRange.fromDate,dateRange.toDate]}
                          value={[dateRange.fromDate, dateRange.toDate]}

                          // showWeekNumbers={true} appearance={"default"} placeholder={t("title.LAST_WEEK")}
                          ranges={[]}
                        />
                        <div style={{marginLeft: 200, marginTop: 10}}>
                          <button style={{width: 40}} onClick={exportPastData}
                                  className="sa-table-btn-secondary-small">
                            {t("button.OK")}
                          </button>
                        </div>

                      </Dropdown.Menu>
                    </Dropdown>


                  </div>

                </div>


                <div className="sa-popup-content" key={index}>

                  {data.current.weather.map((weather, num) => (
                    <div key={num} className={'m-l-19'}>
                      <div className={'weather-pop-flex'}>
                        <div className={'aline'}>
                          <img src={`/images/clouds/${weather.icon}.png`} width={'48px'} className={'mt-1'}
                               style={{mixBlendMode: 'multiply'}}/>
                          <div className={'weather-box-heading weather-pop-flex'}>{weather.main}</div>
                        </div>
                        <div className={'p-16 '}>
                          <div className={'weather-box-temp weather-pop-flex'}>{data.current.temp + " °C"} </div>
                          <div
                            className={'weather-time weather-pop-flex'}> {new Date(data.time).toLocaleTimeString().slice(0, 4) + new Date(data.time).toLocaleTimeString().slice(7)} </div>
                        </div>
                      </div>
                    </div>))}
                  <div className={'m-l-19'}>
                    <div className={'weather-pop-flex'}>
                      <div className={'text-left'}>
                        <div className={'weather-icon-box'}>
                          <img src={Droplet} width={"20px"}/></div>
                      </div>
                      <div className={'p-16 '}>
                        <div className={'weather-box-temp weather-pop-flex'}>{data.current.humidity + " %"}</div>
                        <div className={'weather-name weather-pop-flex'}>Humidity</div>
                      </div>
                    </div>

                  </div>
                  <div className={'m-l-19'}>
                    <div className={'weather-pop-flex'}>
                      <div className={'text-left'}>
                        <div className={'weather-icon-box'}>
                          <img src={Wind} width={"24px"}/></div>
                      </div>
                      <div className={'p-16 '}>
                        <div className={'weather-box-temp weather-pop-flex'}>{data.current.wind_speed + " Km/H"}</div>
                        <div className={'weather-name weather-pop-flex'}>Wind</div>
                      </div>
                    </div>

                  </div>
                  <div className={'m-l-19'}>
                    <div className={'weather-pop-flex'}>
                      <div className={'text-left'}>
                        <div className={'weather-icon-box align-items-center'}>
                          <img src={Rain} width={"24px"}/>
                          {/* <FeatherIcon icon={'cloud-rain'}/> */}
                        </div>
                      </div>
                      <div className={'p-16 '}>
                        <div
                          className={'weather-box-temp weather-pop-flex'}>{data.current.rain ? data.current.rain['1h'] : '0'}</div>
                        <div className={'weather-name weather-pop-flex'}>Rain</div>
                      </div>
                    </div>

                  </div>
                  {/* {isVisibleExport &&<div style={{ marginLeft: 271 }}>
                                        <button onClick={exportPastData}
                                            className="sa-table-btn-secondary-small">
                                            {t("button.OK")}
                                        </button>
                                    </div>} */}

                </div>

              </div>))}
            {/*<div className={'m-l-19'}>*/}
            {/*        <div className={'weather-pop-flex'}>*/}
            {/*            <div className={'text-left'}>*/}

            {/*            </div>*/}
            {/*            <div className={'p-16 '}>*/}
            {/*                <div className={'weather-box-temp weather-pop-flex'}>.</div>*/}
            {/*                <div onClick={exportPastData} className={'weather-name weather-pop-flex'}>Export </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    </div>*/}

            <div className={'sa-popup-content'} style={{marginTop: 40}}>
              <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1'}
                                                  style={(weatherTab === "Temp") && style1 || null} onClick={() => {
                setWeatherTab("Temp")
              }}>Temperature</span></span>
              <span className={"hr-line"}></span>
              {/*<span className={'pl-2 pr-2'}><span className={'weather-pop-tabs pb-2 '} style={(weatherTab === "Precipitation") &&  style1  || null} onClick={()=>{setWeatherTab("Precipitation")}}>Precipitation</span></span>*/}
              {/*<span className={"hr-line"}></span>*/}
              <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1 '}
                                                  style={(weatherTab === "Humidity") && style1 || null} onClick={() => {
                setWeatherTab("Humidity")
              }}>Humidity</span></span>
              <span className={"hr-line"}></span>
              <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1  '}
                                                  style={(weatherTab === "Wind") && style1 || null} onClick={() => {
                setWeatherTab("Wind")
              }}>Wind</span></span>
              <span className={"hr-line"}></span>
              <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1  '}
                                                  style={(weatherTab === "Rain") && style1 || null} onClick={() => {
                setWeatherTab("Rain")
              }}>Rain</span></span>

              <div className={'float-right m-l-a'}>
                <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1  '}
                                                    style={(weatherTime === "Hourly") && style1 || null}
                                                    onClick={() => {
                                                      setWeatherTime("Hourly")
                                                    }}>Hourly</span></span>
                <span className={"hr-line"}></span>
                <span className={'pl-3 pr-3'}><span className={'weather-pop-tabs pb-1 '}
                                                    style={(weatherTime === "Daily") && style1 || null} onClick={() => {
                  setWeatherTime("Daily")
                }}>Daily</span></span>
              </div>

            </div>

            <div className={'sa-popup-content'} style={{marginTop: 5}}>
              {weatherTime === 'Hourly' && loadGraph && dataSet.data && (
                <C3Chart area={{zerobased: true}} padding={{left: 45}} tooltip={{grouped: true}}
                         zoom={dataSet.zoom}
                  // grid= {{
                  //     x: {
                  //     show: true
                  // },
                  //     y: {
                  //     show: true
                  // }
                  // }}


                         data={dataSet.data} subchart={{show: false}} onrendered={styleGraph}
                         axis={dataSet.axis}/>
              )}
              {weatherTime === "Daily" && loadGraphDaily && dataSetDaily.data && (
                <C3Chart area={{zerobased: false}} padding={{left: 45}} tooltip={dataSetDaily.tooltip}
                         zoom={dataSetDaily.zoom}
                         data={dataSetDaily.data} subchart={{show: false}} onrendered={styleGraph}
                         axis={dataSetDaily.axis}/>
              )}

            </div>
          </div>

          <div className={'sa-modal-box sa-modal-box-mobile padding-scroll hide-mobile-weather'}>
            <div className="sa-modal-header">
              <div className={'weather-pop-mobile-heading'}>Weather</div>
              <div className="sa-modal-close" onClick={() => setIsWeather(false)}><FeatherIcon className={"x"}
                                                                                               icon={"x"}/>
              </div>
            </div>
            {weatherActual.map((data, index) => (
              <div className="row mt-5" key={index}>
                {data.current.weather.map((weather, num) => (
                  <div key={num} className={'w-100'}>
                    <div className={'col-6 m-b-28'}>
                      <div className={'row aline'}>
                        <div className={'weather-box-heading col-3'}>
                          <span><img src={`/images/clouds/${weather.icon}.png`} width={'48px'} className={'aline'}
                                     style={{mixBlendMode: 'multiply'}}/></span>
                          <div className={'weather-main'}>{weather.main}</div>
                        </div>
                        <div className={'col-2'}>
                          <div className={'weather-box-temp max-content'}>{data.current.temp + " °C"} </div>
                          <div
                            className={'weather-time max-content weather-box-temp-time'}> {new Date(data.time).toLocaleTimeString().slice(0, 4) + new Date(data.time).toLocaleTimeString().slice(7)} </div>
                        </div>
                      </div>
                    </div>
                    <div className={'p-l-10'}>
                      <div className={'row weather-mobile-box-temp-m'}>
                        <div className={'col-6 m-b-28'}>
                          <div className={'weather-mobile-box-temp'}>{data.current.humidity + " %"}</div>
                          <div className={'weather-mobile-name'}>Humidity</div>
                        </div>
                        <div className={'col-6 m-b-28'}>
                          <div className={'weather-mobile-box-temp '}>{data.current.wind_speed + " Km/H"}</div>
                          <div className={'weather-mobile-name'}>Wind</div>
                        </div>
                        <div className={'col-6'}>
                          <div className={'weather-mobile-box-temp '}>{data.current.wind_deg}&deg;</div>
                          <div className={'weather-mobile-name'}>Wind Direction</div>
                        </div>
                        <div className={'col-6'}>
                          <div
                            className={'weather-mobile-box-temp '}>{data.current.rain ? data.current.rain['1h'] : '0'}</div>
                          <div className={'weather-mobile-name'}>Rain</div>
                        </div>

                      </div>
                    </div>
                  </div>))}
              </div>))}
            <div className={'weather-icon-margin weather-icon-container'}>
              <div
                className={weatherTab === "Temp" ? 'weather-mobile-icon-box weather-icon-selected ' : 'weather-mobile-icon-box'}
                onClick={() => {
                  setWeatherTab("Temp")
                }}>
                <img src={Thermometer} width={'20px'}/>
                {weatherTab === "Temp" && <div className={'weather-mobile-icon-name'}>Temperature</div>}
              </div>
              <div
                className={weatherTab === "Humidity" ? 'weather-mobile-icon-box weather-icon-selected ' : 'weather-mobile-icon-box'}
                onClick={() => {
                  setWeatherTab("Humidity")
                }}>
                <img src={DropletGreen} width={'20px'}/>
                {weatherTab === "Humidity" && <div className={'weather-mobile-icon-name'}>Humidity</div>}
              </div>
              <div
                className={weatherTab === "Wind" ? 'weather-mobile-icon-box weather-icon-selected ' : 'weather-mobile-icon-box'}
                onClick={() => {
                  setWeatherTab("Wind")
                }}>
                <img src={WindGreen} width={'20px'}/>
                {weatherTab === "Wind" && <div className={'weather-mobile-icon-name'}>Wind</div>}
              </div>
              <div
                className={weatherTab === "Rain" ? 'weather-mobile-icon-box weather-icon-selected ' : 'weather-mobile-icon-box'}
                onClick={() => {
                  setWeatherTab("Rain")
                }}>
                <img src={CloudRain} width={'20px'}/>
                {weatherTab === "Rain" && <div className={'weather-mobile-icon-name'}>Rain</div>}
              </div>
            </div>
            <div className={''}>
              {weatherTime === 'Hourly' && dataSet.data && (
                <C3Chart area={{zerobased: false}} padding={{left: 45}} tooltip={dataSet.tooltip} zoom={dataSet.zoom}
                         data={dataSet.data} subchart={{show: false}} onrendered={styleGraph} axis={dataSet.axis}/>
              )}
              {weatherTime === "Daily" && dataSetDaily.data && (
                <C3Chart area={{zerobased: false}} padding={{left: 45}} tooltip={dataSetDaily.tooltip}
                         zoom={dataSetDaily.zoom}
                         data={dataSetDaily.data} subchart={{show: false}} onrendered={styleGraph}
                         axis={dataSetDaily.axis}/>
              )}

            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}
