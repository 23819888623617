import roleJson from "./role/rolePermissions.json"
import roleInvertedJson from "./role/roleInvertedPermissions.json"
import {getRole, isAccountUser, isSuperLevelUsers} from "../shared/utils/SharedAuthentication";

const {BlobServiceClient} = require("@azure/storage-blob");
const account = "storagetestsabruntha";
const sas = "sp=racw&st=2023-04-12T03:35:36Z&se=2024-07-18T11:35:36Z&sv=2021-12-02&sr=c&sig=LYWslfoudlsDoExWQ%2Fssgybqdca0t%2FpHsfG2JGy8lvo%3D";
const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net/?${sas}`);

export const containerClient = blobServiceClient.getContainerClient('images');

// export function hasSettingsPermission() {
//   return isSuper() || getRole() !== 3000 || getRole() !== 500;
// }
//
// export function hasCashFlowPermission() {
//   return isSuper() || !([3000,500,510,410,210,55,54,53,52,51].includes(getRole()));
// }

export function isKeellsAccount(accountId) {
  if (process.env.REACT_APP_DOMAIN === "KEELLS") {
    return true;
  }

  return ["5f0ed52a2d3b872199d53040", "62d7fefb6f3c941599959548"].includes(accountId);
}

// export function hasDailyUpdatePermission() {
//   return isSuper() || !([3000,500,510,410,210,55,54,53,52,51].includes(getRole()));
// }
//
// export function hasWritePermission() {
//   return getRole() !== 2000;
// }

function hasPermissionStatic(feature) {
  switch (feature) {
    case "PLOT_ALERT_ADVANCED_SETTING":
    case "FARM_ADVANCED_SETTING":
      if (getRole() < 10) {
        return true;
      }
      break;
    case "SETTINGS":
      if ([500, 510].includes(getRole())) {
        return false;
      }
      if (getRole() >= 3000 && getRole() <= 10000) {
        return true;
      }
      break;
    case "CORPORATE_CREATE":
      if (getRole() < 100) {
        return true;
      }
      break;
    case "CORPORATE_SETTING":
      if (getRole() < 100 || getRole() === 100 || getRole() === 200) {
        return true;
      }
      break;
    case "SYSTEM_SETTING":
    case "BUYER_PROFILE_ADMIN":
      return getRole() < 10;
    case "BUYER_PROFILE":
      return getRole() === 100000;
    case "CCR_PROFILE":
      return getRole() === 9;
    case "CORPORATE_ACCESS":
      return getRole() === 100 || getRole() === 200 || getRole() === 300 || getRole() === 500 || getRole() === 510;
    case "VALVE_CARD":
      if (getRole() < 10) {
        return true;
      }
    case "CCO":
    case "FMS":
      return isSuperLevelUsers() || getRole() === 500 || getRole() === 200 || getRole() === 100 || getRole() === 10;
  }
  return false;
}

function hasPermissionInverted(feature, access) {      // returns false if the feature.access is not in roleInvertedPermissions.json
  if (access && access !== "VIEW") {                  // or includes roleId. Used for disabling feature which doesn't have full access
    feature = feature + "." + access;
  }

  // console.log(feature);
  let featureAccess = roleInvertedJson[feature];

  return featureAccess && !featureAccess.includes(getRole());
}

function hasPermissionLimited(feature, access) {        // returns true only if the feature.access is in roleInvertedPermissions.json
  if (access && access !== "VIEW") {                   // and includes roleId. Used for disabling feature which has full access
    feature = feature + "." + access;
  }

  let featureAccess = roleInvertedJson[feature];

  return featureAccess && featureAccess.includes(getRole());
}

export function hasPermission(feature, access = "VIEW") {

  if (isSuperLevelUsers() || isAccountUser()) {
    if (isAccountUser() && feature === "SETTINGS.SYSTEM_SETTING.CROP_AGRONOMY") {
      return false
    }

    if (isAccountUser() && feature === "BUYER_PROFILE") {
      return false
    }
    return true
  }

  let permission = checkPermission(roleJson[getRole()], feature, access);
  if (permission) {
    return true;
  }

  permission = hasPermissionInverted(feature, access);
  if (permission) {
    return true;
  }

  return hasPermissionStatic(feature, access);
}

function checkPermission(userAllAccessJson, requestedFeature, access) {
  if (!userAllAccessJson) {
    return false;
  }

  let feature = requestedFeature.split(".");

  if (feature.length === 1) {
    if (access === "VIEW" && userAllAccessJson[feature]) {
      return true;
    } else if (userAllAccessJson[feature] && userAllAccessJson[feature].DEFAULT) {

      return userAllAccessJson[feature].DEFAULT.includes(access);
    } else if (userAllAccessJson[feature] === "FULL_ACCESS") {
      if (hasPermissionLimited(requestedFeature, access)) {
        return false
      } else {
        return true
      }
    } else {
      return false;
    }
  } else {
    let current = feature.shift();
    if (userAllAccessJson[current] === "FULL_ACCESS") {
      if (hasPermissionLimited(requestedFeature, access)) {
        return false
      } else {
        return true
      }
    } else {
      return checkPermission(userAllAccessJson[current], feature.join("."), access);
    }
  }
}
