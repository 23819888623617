import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import 'react-input-range/lib/css/index.css';
import {
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader,
  toggleTimePicker
} from "../../../shared/actions/setting";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateAutomationMaxTime} from "../../../utils/FormValidationRules";
import axios from "axios";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotAutomationMaxTime = (props) => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  const {
    values,
    errors,
    handleChange,
    handleOnBlur,
    initForm,
    handleSubmit,
    setValue
  } = FormHandler(saveAutomation, validateAutomationMaxTime);

  function saveAutomation() {
    const { hours, minutes } = values;
    const maxTime = parseInt(hours, 10)*60 + parseInt(minutes, 10);

    if (props.irrigation && props.irrigation.automation) {
        props.irrigation.automation.maxTime = maxTime;

        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/plot/` + props.plotId + '/irrigation', props.irrigation)
        .then(res => {
            props.updateIrrigation(res.data.content);
            setValue({time: `${hours} hrs ${minutes} mins`})
            toast.success("Maximum irrigation time updated successfully");
        })
        .catch(error => {
            if (error.response && error.response.status === 422) {
              toast.error(error.response.data.message);
            }
        })
        .finally(() => {
            dispatch(toggleLoader(false));
        });
    }
  }

  useEffect(() => {
    if (!props.irrigation || !props.irrigation.automation || !props.irrigation.automation.maxTime) {
        return;
    }

    const maxTime = props.irrigation.automation.maxTime;
    const hours = Math.floor(maxTime / 60);
    const minutes = maxTime % 60;

    setValue({
      hours: hours.toString(),
      minutes: minutes.toString(),
      time: `${hours} hrs ${minutes} mins`
    });

  }, [props.irrigation]);


  return (
    <div>
      <form className={"single-card pis-border p-12"} onSubmit={handleSubmit}>
        <div className="sa-popup-content">
            <div className="col-md-6">
                <div className="form-group m-b-16">
                    <label htmlFor="email">{t("title.HOURS")}</label>
                    <input onChange={handleChange} onBlur={handleOnBlur}
                            value={values.hours || ''} type="number"
                            className={`form-control ${errors.hours && "warning-input"}`}
                            name="hours"
                            placeholder={t("placeHolder.ENTER_HOURS")}/>
                    {errors.hours && (<p className="warning-input-msg ">{errors.hours}</p>)}
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group m-b-16">
                    <label htmlFor="email">{t("title.MINUTES")}</label>
                    <input onChange={handleChange} onBlur={handleOnBlur}
                            value={values.minutes || ''} type="number"
                            className={`form-control ${errors.minutes && "warning-input"}`}
                            name="minutes"
                            placeholder={t("placeHolder.ENTER_MINUTES")}/>
                    {errors.minutes && (<p className="warning-input-msg ">{errors.minutes}</p>)}
                </div>
            </div>
      </div>
      <div className="parent-container">
        <div className="time-container">
            <div className="">
                <label htmlFor="email">{t("title.SELECTED_TIME")}</label>
                <input  value={values.time || ''} type="text"
                        className={`form-control`}
                        name="time"
                        disabled/>
            </div>
        </div>
      </div>
      <div className={"schedule-display float-r"}>
            <button className="btn btn-sa-primary-cashFlow schedule-btn">
              {t("button.SAVE")}
            </button>
     </div>
     </form>
    </div>
  );
};

export default PlotAutomationMaxTime;
