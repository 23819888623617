import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {TOGGLE} from "../../../utils/Enum";
import {useDispatch, useSelector} from "react-redux";
import {getBottomReached} from "../../../actions/plot";
import { hasPermission } from "../../../utils/Authentication";
import {IRRIGATION_MODE} from "../../../utils/Enum";
import {useTranslation} from 'react-i18next';
import PlotAutomationSchedule from "./plot-automation-schedule";
import PlotAutomationTab from "./plot-automation-tab";
import PlotAutomationMaxTime from "./plot-automation-maxTime";
import PlotIrrigationAutomation from "./plot-irrigation-automation";

const PlotAutomationLayout = (props) => {
  const {plotId, farmId} = useParams();
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const [showFadeEffect, setShowFadeEffect] = useState(false);

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);


  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  function bottomReached() {
    const offset = document.getElementById('listView').offsetHeight;
    const scrolledHeight = document.getElementById('listView').scrollTop;
    dispatch(getBottomReached((offset + scrolledHeight + 1) >= document.getElementById('listView').scrollHeight))
  }

  return (
    <div >
      <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list bg-img-none" : ""}/>
      {/*<Container>*/}

      <PlotAutomationTab plotId={plotId} farmId={farmId} isFarmIrrigation={props.isFarmIrrigation}/>
      {hasPermission("WRITE") &&
          <div className="m-t-8 pull-right">
            <button
              hidden={(props.buttonHide || props.isAddIrrigation || location.pathname.includes("/stepThree") ||
                      (props.clusters.length === 0 && props.irrigation.mode === IRRIGATION_MODE.AUTOMATION && !location.pathname.includes("/stepTwo")) || 
                      (props.clusters.length !== 0 && props.irrigation.mode === IRRIGATION_MODE.AUTOMATION && !location.pathname.includes("/stepOne") && !location.pathname.includes("/stepTwo")))}
              className="btn btn-sa-secondary pis-add" style={{padding: 8}} onClick={props.addIrrigation}><FeatherIcon
              icon={"plus"}/> <span className={"hide-375"}>{t("button.ADD")}</span>
            </button>
          </div>
        }
      <div className={`${location.pathname.includes("/stepThree") ? "pb-20" : "pb-70"}`}></div>
      <div className={"half-list-container"}>
        <Switch>
        <Route
            path={`${!props.isFarmIrrigation ? "/:farmId/plot/:plotId/schedule/stepOne" : "/:farmId/irrigation-setting/stepOne"}`}
            render={(routeProps) => (
              <PlotIrrigationAutomation {...routeProps} plotId={props.plotId} isAddIrrigation={props.isAddIrrigation} 
              irrigation={props.irrigation}
              updateIrrigation={props.updateIrrigation}
              changeAdd={props.changeAdd}
              isDeleteIrrigation = {props.isDeleteIrrigation}
              setIsDeleteIrrigation = {props.setIsDeleteIrrigation}
              hide={props.hide}
              farmId={farmId} />
            )}
          />
          <Route
            path={`${!props.isFarmIrrigation ? "/:farmId/plot/:plotId/schedule/stepTwo" : "/:farmId/irrigation-setting/stepTwo"}`}
            render={(routeProps) => (
              <PlotAutomationSchedule {...routeProps} plotId={props.plotId} isAddIrrigation={props.isAddIrrigation} 
              irrigation={props.irrigation}
              updateIrrigation={props.updateIrrigation}
              changeAdd={props.changeAdd}
              isDeleteIrrigation = {props.isDeleteIrrigation}
              setIsDeleteIrrigation = {props.setIsDeleteIrrigation}
              hide={props.hide}
              farmId={farmId} />
            )}
          />
          <Route
            path={`${!props.isFarmIrrigation ? "/:farmId/plot/:plotId/schedule/stepThree" : "/:farmId/irrigation-setting/stepThree"}`}
            render={(routeProps) => (
              <PlotAutomationMaxTime {...routeProps}  plotId={props.plotId} 
              irrigation={props.irrigation}
              updateIrrigation={props.updateIrrigation} />
            )}
          />
        </Switch>
      </div> 
    </div>
  );
};

export default PlotAutomationLayout;
