import React, {useEffect, useState} from "react";
import axios from "axios";
import {toggleLoader} from "../../../shared/actions/setting";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import FeatherIcon from "feather-icons-react";
import {getUserId} from "../../../shared/utils/SharedAuthentication";

const PlotIrrigationDaily = (props) => {
  const dispatch = useDispatch();
  const [dailyIrrigation, setDailyIrrigation] = useState({});
  const [error, setError] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const daily = {
    MONDAY: 0,
    TUESDAY: 0,
    WEDNESDAY: 0,
    THURSDAY: 0,
    FRIDAY: 0,
    SATURDAY: 0,
    SUNDAY: 0
  };

  useEffect(() => {
    if (isEdit) {
      return;
    }
    if (props.plotResponse.daily) {
      setDailyIrrigation(props.plotResponse.daily);
    } else {
      setDailyIrrigation(daily);
    }
  }, [isEdit]);

  useEffect(() => {
    if (props.plotResponse.daily) {
      setDailyIrrigation(props.plotResponse.daily);
    } else {
      setDailyIrrigation(daily);
    }
  }, [props.plotResponse]);

  function formChanges(e) {
    const newValues = {...dailyIrrigation};
    newValues[e.target.name] = Number(e.target.value);
    setDailyIrrigation(newValues);
  }

  function saveIrrigation(e) {
    e.preventDefault();
    dispatch(toggleLoader(true));

    const plotResponse = props.plotResponse;
    plotResponse.daily = dailyIrrigation;

    if (props.plotResponse.daily) {
      axios.put(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/plot/' + props.plotId + '/irrigation', plotResponse)
        .then(function (response) {
          // setDailyIrrigation(response.data.content.daily);
          dispatch(toggleLoader(false));
          toast.success("Schedule updated successfully");
          setIsEdit(false);
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
          }
          dispatch(toggleLoader(false));

        });
    } else {
      axios.post(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/plot/' + props.plotId + '/irrigation', plotResponse)
        .then(function (response) {
          // setDailyIrrigation(response.data.content.daily);
          dispatch(toggleLoader(false));
          toast.success("Schedule updated successfully");
          setIsEdit(false);
        })
        .catch(function (error) {
          if (error.response && error.response.status === 422) {
            setError(error.response.data.message);
          }
          dispatch(toggleLoader(false));

        });
    }
  }

  return (
    <div>
      <form onSubmit={saveIrrigation}>
        <div className="">
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Monday</div>
            <input type={"text"} value={dailyIrrigation["MONDAY"] || 0}
                   onChange={formChanges} name="MONDAY" id="subdomain" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Tuesday</div>
            <input type={"text"} value={dailyIrrigation["TUESDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="TUESDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Wednesday</div>
            <input type={"text"} value={dailyIrrigation["WEDNESDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="WEDNESDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Thursday</div>
            <input type={"text"} value={dailyIrrigation["THURSDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="THURSDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Friday</div>
            <input type={"text"} value={dailyIrrigation["FRIDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="FRIDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Saturday</div>
            <input type={"text"} value={dailyIrrigation["SATURDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="SATURDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
          <div className="schedule-day-mode">
            <div className="schedule-day-mode-day">Sunday</div>
            <input type={"text"} value={dailyIrrigation["SUNDAY"] || 0} onChange={formChanges}
                   id="subdomain" name="SUNDAY" disabled={!isEdit}/>
            <input type="text" id="subdomaintwo" placeholder="mins." disabled/>
          </div>
        </div>
        {!isEdit ?
          <div className="h-44 plot-edit">
            <span onClick={() => setIsEdit(true)}
                  className={'edit-btn-color edit-btn edit-btn-color p-t-8 btn btn-sa-secondary w-120p btn-size'}>
            <FeatherIcon className={'edit-btn-size'} icon={"edit-2"}/>
            <button type="button" className="hide-425 background-color">Edit</button>
            </span>
          </div> :
          <div className="h-44 plot-edit">
            <button type="button" className="btn btn-sa-secondary w-120p m-r-8"
                    onClick={() => setIsEdit(false)}>Cancel
            </button>
            <button type="submit" className="btn btn-sa-secondary w-120p">Save</button>
          </div>
        }
      </form>
    </div>

  )

};
export default PlotIrrigationDaily;
